<template>
<div class="modal fade " id="dateRangeModal" tabindex="-1" role="dialog" aria-labelledby="dateRangeModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Choose Date Range</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>Range</label>
                            <VueMultiselect v-model="selectedRange" class="" :allow-empty="false" :options="defaultDataRange" :close-on-select="true" placeholder="Select range" label="name" track-by="value" :show-labels="false" />

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="selectedRange?.value === 0">
                            <div class="form-group">
                                <label>From</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="w-100 ">
                                        <DatePicker v-model="formDate" :masks="{input: ['DD MMMM YYYY']}" :max-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                            <template v-slot="{ inputValue ,togglePopover  }">
                                                <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                    <i class='bx bx-calendar mr-1'></i>
                                                    <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                </div>
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="selectedRange?.value === 0">
                            <div class="form-group" :class="formDate ? '' : 'hidden'">
                                <label>To</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="w-100 ">
                                        <DatePicker v-model="toDate" :masks="{input: ['DD MMMM YYYY']}" :min-date='formDate' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                            <template v-slot="{ inputValue ,togglePopover  }">
                                                <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                    <i class='bx bx-calendar mr-1'></i>
                                                    <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                </div>
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="!isFilterButtonDisabled" @click="filter()" type="button" class="btn btn-primary" >
                    Filter
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    DatePicker
} from 'v-calendar';
export default {
    name: "DateRangeModal",
    components: {
        DatePicker
    },
    emits:['getAppointmentsReportHandler','updateAppointemntReportFilterDate'],
    data() {
        return {
            toDate: '',
            formDate: '',
            selectedRange: {
                name: 'last 30 days',
                value: 30,
            },
            defaultDataRange: [{
                    name: 'last 30 days',
                    value: 30,
                },
                {
                    name: 'last 15 days',
                    value: 15,
                },
                {
                    name: 'last 7 days',
                    value: 7,
                },
                {
                    name: 'custom',
                    value: 0,
                },

            ]

        }
    },
    watch: {
        selectedRange(currentSelectedRange){
            if (currentSelectedRange && (currentSelectedRange.value !==  0)) {
                switch (currentSelectedRange.value) {
                    case 30:
                        this.formDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
                        this.toDate =  new Date(new Date()).toISOString().slice(0, 10);
                        break;
                    case 15:
                        this.formDate = new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().slice(0, 10);
                        this.toDate =  new Date(new Date()).toISOString().slice(0, 10);
                        break;
                    case 7:
                        this.formDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().slice(0, 10);
                        this.toDate =  new Date(new Date()).toISOString().slice(0, 10);
                        break;
                
                    default:
                        break;
                }
            }
            else {
                this.formDate = "";
                this.toDate = "";
            }
        }
    },
    computed: {
        isFilterButtonDisabled(){
            if (this.selectedRange.value === 0) {
                return (this.formDate && this.toDate);
            }
            return true;
        }
    },
    methods: {
        filter(){
            this.$emit('updateAppointemntReportFilterDate',{
                selectedRange:this.selectedRange,
                formDate : this.formDate,
                toDate : this.toDate,
            })
            this.$emit('getAppointmentsReportHandler', {
                from_date:this.formDate ,
                to_date: this.toDate ,
            });
            document.querySelector('[data-target="#dateRangeModal"]').click();

          
        }
   
    },

}
</script>

<style>

</style>
