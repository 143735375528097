<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class=" d-sm-flex  align-items-center pt-2 pt-md-2 pt-xl-0 ">

            </div>
            <div class="content-body">
                <div class="users-list-table">
                    <div class="card shadow-none">
                        <div class="card-body " :style="{backgroundColor:'#f2f4f4'}">
                            <div class="d-flex align-items-center">
                                <h4 class="">Appointment Reports</h4>
                                <div class="ml-1">
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-2 d-flex align-items-center">
                                            <div>
                                                <input v-model="graphicalView" type="checkbox" class="custom-control-input" checked id="isRescheduledByCustomer">
                                                <label class="custom-control-label mr-1" for="isRescheduledByCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ graphicalView ? "Graphical view" : "Normal view" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h6 class="text-info mb-2">
                                You are showing data of Last  {{selectedRange.value !== 0 ? selectedRange.name : `${this.formDate} to ${this.toDate}`}} <button type="button" class="btn btn-link p-0" data-toggle="modal" data-target="#dateRangeModal">Customise</button>
                            </h6>
                            <div class="modal fade" id="filterData" tabindex="-1" role="dialog" aria-labelledby="filterDataLabel" aria-hidden="true">
                                <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="filterDataLabel">Choose Date Range</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 col-lg-3 mb-2" >
                                    <div class="card  h-100 ">
                                    
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Appointments</h5>
                                            <h5 class="text-success text-right mb-0">{{statusWiseTotalAppointments(statusWiseAppointment)}}</h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleStatusWiseAppointmentValue,singleStatusWiseAppointmentKey) in  statusWiseAppointment" :key="singleStatusWiseAppointmentKey">

                                                        <td>{{singleStatusWiseAppointmentKey}}</td>
                                                        <td class="text-right">{{singleStatusWiseAppointmentValue}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="statusWiseAppointmentChartIdParent">
                                                    <canvas id="statusWiseAppointmentChartId"></canvas>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card h-100 ">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Sales</h5>
                                            <h5 class="text-success text-right mb-0">${{parseFloat(sellWiseTotalAppointmentPrice(sellWiseAppointment)/100).toFixed(2)}}</h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleSellWiseAppointmentValue,singleSellWiseAppointmentKey) in  sellWiseAppointment" :key="singleSellWiseAppointmentKey">

                                                        <td>{{singleSellWiseAppointmentKey}}</td>
                                                        <td class="text-right">{{parseFloat(singleSellWiseAppointmentValue/100).toFixed(2)}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="sellWiseAppointmentChartIdParent">
                                                    <canvas id="sellWiseAppointmentChartId"></canvas>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- paymentTypeWiseAppointmentAmount -->
                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card h-100 ">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Payment Type</h5>
                                            <h5 class="text-success text-right mb-0">{{Object.keys(paymentTypeWiseAppointmentAmount).length}}</h5>
                                        </div>
                                        <div class="card-body">

                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singlePaymentTypeWiseAppointmentValue,singlePaymentTypeWiseAppointmentKey) in  paymentTypeWiseAppointmentAmount" :key="singlePaymentTypeWiseAppointmentKey">

                                                        <td>{{singlePaymentTypeWiseAppointmentKey}}</td>
                                                        <td class="text-right">{{parseFloat(singlePaymentTypeWiseAppointmentValue/100).toFixed(2)}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="paymentTypeWiseAppointmentChartIdParent">
                                                    <canvas id="paymentTypeWiseAppointmentChartId"></canvas>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card h-100 ">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Duration</h5>
                                            <h5 class="text-success text-right mb-0">{{totalAppointmentDuration(statusWiseDuration)}} Hrs.</h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleStatusWiseAppointmentDurationValue,singleStatusWiseAppointmentDurationKey) in  statusWiseDuration" :key="singleStatusWiseAppointmentDurationKey">

                                                        <td>{{singleStatusWiseAppointmentDurationKey}}</td>
                                                        <td class="text-right">{{singleStatusWiseAppointmentDurationValue}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="statusWiseAppointmentDurationChartIdParent">
                                                    <canvas id="statusWiseAppointmentDurationChartId"></canvas>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card h-100 ">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Customer Type</h5>
                                            <h5 class="text-success text-right mb-0"> {{Object.keys(customerWiseAppointment).length}}</h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleCustomerTypeWiseAppointmentValue,singleCustomerTypeWiseAppointmentKey) in  customerWiseAppointment" :key="singleCustomerTypeWiseAppointmentKey">

                                                        <td>{{singleCustomerTypeWiseAppointmentKey}}</td>
                                                        <td class="text-right">{{singleCustomerTypeWiseAppointmentValue}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="customerWiseAppointmentChartIdParent">
                                                    <canvas id="customerWiseAppointmentChartId"></canvas>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card  h-100">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Technician</h5>
                                            <h5 class="text-success text-right mb-0">{{technicianWiseAppointment.length}} </h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleTechnicianWiseAppointment,index) in  technicianWiseAppointment" :key="index">

                                                        <td>{{singleTechnicianWiseAppointment.name}}</td>
                                                        <td class="text-right">{{singleTechnicianWiseAppointment.total}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="technicianWiseAppointmentChartIdParent">
                                                    <canvas id="technicianWiseAppointmentChartId"></canvas>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3 mb-2">
                                    <div class="card  h-100">
                                        <div class="card-header border-bottom-2">
                                            <h5 class="text-dark ">Week Days</h5>
                                            <h5 class="text-success text-right mb-0">7</h5>
                                        </div>
                                        <div class="card-body ">
                                            <table v-if="!graphicalView" class="table table-hover text-secondary table-md mb-0">
                                                <tbody>
                                                    <tr v-for="(singleWeekWiseAppointmentValue,singleWeekWiseAppointmentKey) in  appointmentData" :key="singleWeekWiseAppointmentKey">

                                                        <td>{{singleWeekWiseAppointmentKey}}</td>
                                                        <td class="text-right">{{singleWeekWiseAppointmentValue}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div :class="!graphicalView ? 'hidden mt-1' : 'mt-1'">
                                                <div id="weekWiseAppointmentChartIdParent">
                                                    <canvas id="weekWiseAppointmentChartId"></canvas>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <DateRangeModal @updateAppointemntReportFilterDate="updateAppointemntReportFilterDate" @getAppointmentsReportHandler="getAppointmentsReportHandler" />
        </section>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import DateRangeModal from "@/views/backEnd/reports/includes/DateRangeModal";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

import Chart from 'chart.js/auto';
// import { getRelativePosition } from 'chart.js/helpers';

import {
    mapActions,
    mapGetters
} from 'vuex';

export default {
    name: "AppointmentReport",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        DateRangeModal,

        AppLayout,

    },
    data() {
        return {
            toDate: '',
            formDate: '',
            selectedRange: {
                name: 'last 30 days',
                value: 30,
            },
            getAppointmentsReportParams: {
                from_date: '',
                to_date: '',
                franchisee_id:'',
            },
            statusWiseAppointmentCharObj: {},
            graphicalView: true,
            statusWiseAppointment: {},
            sellWiseAppointment: {},
            paymentTypeWiseAppointmentAmount: {},
            statusWiseDuration: {},
            customerWiseAppointment: {},
            technicianWiseAppointment: [],
            appointmentData: {}

        }

    },
    computed: {
        ...mapGetters({
            authFranchisee: 'appAuthLogin/authFranchisee',
            appointmentsReport: "appAppointments/appointmentsReport",
        }),

    },
    beforeUpdate() {

    },
    watch: {
     
        appointmentsReport() {
            this.statusWiseAppointment = this.appointmentsReport ?.statusWiseAppointment ?? {};
            if (!this.isEmptyObject(this.statusWiseAppointment)) {
                const labels = [];
                const data = [];
                for (const key in this.statusWiseAppointment) {
                    labels.push(key);
                    data.push(this.statusWiseAppointment[key]);
                }

                document.querySelector("#statusWiseAppointmentChartIdParent").innerHTML = ' <canvas id="statusWiseAppointmentChartId" height="200px"></canvas>';
                const statusWiseAppointmentCharObj = document.getElementById('statusWiseAppointmentChartId');
                new Chart(statusWiseAppointmentCharObj, this.generateChart(data, labels, 'pie', 'status', false));
                

            }
            this.sellWiseAppointment = this.appointmentsReport ?.sellWiseAppointment ?? {};
            if (!this.isEmptyObject(this.sellWiseAppointment)) {
                const labels = [];
                const data = [];
                for (const key in this.sellWiseAppointment) {
                    labels.push(key);
                    data.push(parseFloat(this.sellWiseAppointment[key] / 100).toFixed(2));
                }

                document.querySelector("#sellWiseAppointmentChartIdParent").innerHTML = ' <canvas id="sellWiseAppointmentChartId" height="200px"></canvas>';
                const sellWiseAppointmentChartObj = document.getElementById('sellWiseAppointmentChartId');
                new Chart(sellWiseAppointmentChartObj, this.generateChart(data, labels, 'doughnut', 'sale', true));

               

            }

            this.paymentTypeWiseAppointmentAmount = this.appointmentsReport ?.paymentTypeWiseAppointmentAmount ?? {};
            if (!this.isEmptyObject(this.paymentTypeWiseAppointmentAmount)) {
                const labels = [];
                const data = [];
                for (const key in this.paymentTypeWiseAppointmentAmount) {
                    labels.push(key);
                    data.push(parseFloat(this.paymentTypeWiseAppointmentAmount[key] / 100).toFixed(2));
                }

                document.querySelector("#paymentTypeWiseAppointmentChartIdParent").innerHTML = ' <canvas id="paymentTypeWiseAppointmentChartId" height="200px"></canvas>';
                const paymentTypeWiseAppointmentChartObj = document.getElementById('paymentTypeWiseAppointmentChartId');
                new Chart(paymentTypeWiseAppointmentChartObj, this.generateChart(data, labels, 'line', 'Payment Type', true));

             

            }

            this.statusWiseDuration = this.appointmentsReport ?.statusWiseDuration ?? {};
            if (!this.isEmptyObject(this.statusWiseDuration)) {
                const labels = [];
                const data = [];
                for (const key in this.statusWiseDuration) {
                    labels.push(key);
                    data.push(parseFloat(this.statusWiseDuration[key]).toFixed(2));
                }

                document.querySelector("#statusWiseAppointmentDurationChartIdParent").innerHTML = ' <canvas id="statusWiseAppointmentDurationChartId" height="200px"></canvas>';
                const statusWiseAppointmentDurationChartObj = document.getElementById('statusWiseAppointmentDurationChartId');
                new Chart(statusWiseAppointmentDurationChartObj, this.generateChart(data, labels, 'bar', 'Appointment Duration', true));

              

            }

            this.customerWiseAppointment = this.appointmentsReport ?.customerWiseAppointment ?? {};
            if (!this.isEmptyObject(this.customerWiseAppointment)) {
                const labels = [];
                const data = [];
                for (const key in this.customerWiseAppointment) {
                    labels.push(key);
                    data.push(this.customerWiseAppointment[key]);
                }

                document.querySelector("#customerWiseAppointmentChartIdParent").innerHTML = ' <canvas id="customerWiseAppointmentChartId" height="200px"></canvas>';
                const customerWiseAppointmentChartObj = document.getElementById('customerWiseAppointmentChartId');
                new Chart(customerWiseAppointmentChartObj, this.generateChart(data, labels, 'bar', 'Customer Type', true));

              

            }

            this.technicianWiseAppointment = this.appointmentsReport ?.technicianWiseAppointment ?? {};
            if (!this.isEmptyObject(this.technicianWiseAppointment)) {
                const labels = [];
                const data = [];

                this.technicianWiseAppointment.map((singleTech) => {
                    data.push(singleTech.total)
                    labels.push(singleTech.name)
                })

                document.querySelector("#technicianWiseAppointmentChartIdParent").innerHTML = ' <canvas id="technicianWiseAppointmentChartId" height="200px"></canvas>';
                const customerWiseAppointmentChartObj = document.getElementById('technicianWiseAppointmentChartId');
                new Chart(customerWiseAppointmentChartObj, this.generateChart(data, labels, 'line', 'Technician', true));

              

            }

            this.appointmentData = this.appointmentsReport ?.appointmentData ?? {};
            if (!this.isEmptyObject(this.appointmentData)) {
                const labels = [];
                const data = [];

                for (const key in this.appointmentData) {
                    labels.push(key);
                    data.push(this.appointmentData[key]);
                }

                document.querySelector("#weekWiseAppointmentChartIdParent").innerHTML = ' <canvas id="weekWiseAppointmentChartId" height="200px"></canvas>';
                const weekWiseAppointmentChartIdObj = document.getElementById('weekWiseAppointmentChartId');
                new Chart(weekWiseAppointmentChartIdObj, this.generateChart(data, labels, 'line', 'Week', true));

               

            }
        }
    },
    methods: {
        ...mapActions({
            getAppointmentsReport: "appAppointments/getAppointmentsReport",
        }),
        isEmptyObject(obj) {
            for (var x in obj) {
                return false;
            }
            return true;
        },
        async getAppointmentsReportHandler(appointmentsReportParams) {
            if (this.authFranchisee.id) {
                this.appointmentsReportParams.franchisee_id = this.authFranchisee.id;
            }
            this.loader(true);
            const response = await this.getAppointmentsReport(appointmentsReportParams);
            this.loader(false);
            if (response.message && response.status !== 200) {
                this.showToastMessage(response);
                return;
            }

        },
        updateAppointemntReportFilterDate(dateRangeData){
            this.selectedRange = dateRangeData.selectedRange;
            this.formDate = dateRangeData.formDate;
            this.toDate = dateRangeData.toDate;
        },
        statusWiseTotalAppointments(statusWiseAppointment) {

            let total = 0;
            for (const key in statusWiseAppointment) {
                total += statusWiseAppointment[key]
               
            }
            return total;
        },
        sellWiseTotalAppointmentPrice(sellWiseAppointment) {

            let total = 0;
            for (const key in sellWiseAppointment) {
                total += sellWiseAppointment[key]
                
            }
            return total;
        },
        totalAppointmentDuration(statusDuration) {

            let totalHours = 0;
            let totalMin = 0;
          
            for (const key in statusDuration) {
                totalHours = parseInt(totalHours) + parseInt(statusDuration[key].split(':')[0]);
                totalMin = parseInt(totalMin) + parseInt(statusDuration[key].split(':')[1]);
            }
           
            return `${totalHours}`;
        },
        generateChart(data, labels, type, label, animation) {
            const chart = {
                type: type,
                data: {
                    labels: labels,
                    datasets: [{
                        label: label,
                        data: data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 2
                    }],
                },
                options: {
                    responsive:true,
                    showAllTooltips: true,

                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            };
            if (animation) {
                chart.animation = {
                    tension: {
                        easing: 'linear',
                        from: 1,
                        to: 0,

                    }
                }
            }

            return chart;
        }

    },
    mounted() {

        this.getAppointmentsReportHandler(this.getAppointmentsReportParams);
    },

}
</script>

<style scoped>
tr>td {
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
}
</style>
